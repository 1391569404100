body {
    font-family: poppins,montserrat,system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    line-height: 1.75;
    font-weight: 300;
}

a {
    color: dodgerblue;
    text-decoration-thickness: 1px;
    text-underline-offset: 1px;
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
    font-family: montserrat;
}

ol ::marker {
    color: grey;
    font-size: smaller;
    font-weight: 300;
}

.filter-list-item {
    margin-top: 1.5rem;
}
.filter-list-item > a {
    font-weight: 500;
}

.list-group-item.active {
    background-color: dodgerblue;
    border-radius: 0.25rem;
    border: 0;
}

.list-item-details {
    margin-top: .5em;
}

.list-item-details a {
    color: #212529 !important;
    text-decoration-color: darkgrey;
}
.list-item-details a:hover {
    text-decoration: underline;
}

ul.fa-ul > li > .fa-li {
    font-size: .75rem;
}

a {
    _text-decoration: none;
    _color: darkcyan;
}
a:hover {
    _text-decoration: underline;
}


.filter-menu-item .remove-filter {
    display: none;
}

.filter-menu-item.active .remove-filter {
    display: inline-block;
    min-height: 1em;
    min-width: 1em;
}

.filter-menu-item.inactive .remove-filter,
.filter-menu-item.unique .remove-filter,
.filter-menu-item.unique-filtered .remove-filter {
    display: none;
}

.filters-menu .filter-menu-item.inactive {
    display: none !important;
}

.filter-menu-item.active .num-vacs,
.filter-menu-item.inactive .num-vacs,
.filter-menu-item.unique .num-vacs,
.filter-menu-item.unique-filtered .num-vacs {
        display: none;
}

.filters-menu .filter-menu-item.unique,
.filters-menu .filter-menu-item.unique-filtered {
    color: grey;
    border-radius: 0.25rem;
    border: 1px solid grey;
}

.list-group-item:hover:not(.active):not(.unique):not(.unique-filtered) {
    background-color: rgba(10, 88, 202, .25);
    border-color: rgba(10, 88, 202, .25);
    cursor: pointer;
}

.list-group-item {
    padding: .5rem;
}

.filter-menu-item .btn-close,
.filter-menu-item .badge.num-vacs {
    margin-left: .5rem;
}

.breadcrumb {
    font-size: smaller;
}

.accordion-header h2 {
    margin: 0;
}

// md
@media (min-width: 768px) {
    .min-vh-md-100 {
        min-height: 100vh !important;
    }
    .layout--home .min-vh-md-100,
    .layout--vsection .min-vh-md-100 {
        min-height: inherit !important;
    }
    .vh-md-100 {
        height: 100vh !important;
    }
    .filters-menu {
        display: block;
    }
    .accordion-collapse {
        border: 0;
    }
}

.filter-list-item.filtered-out {
    display: none;
}

.num-vacs-total {
    font-size: 53.684%;
}

.dropdown-menu.show {
    z-index: 2000;
}

.my-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}
.mt-6 {
    margin-top: 5rem !important;
}
.mb-6 {
    margin-bottom: 5rem !important;
}
.py-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}
.pt-6 {
    padding-top: 5rem !important;
}
.pb-6 {
    padding-bottom: 5rem !important;
}

.listing-group {
    margin-top: 2em;
    text-transform: uppercase;
    color: #6c757d;
}
.listing-item-list {
    padding: 0;
}
.listing-item {
    list-style: none;
    line-height: 1.75em;
}

.navbar-brand .text-logo {
    font-family: montserrat;
    font-weight: 200;
    font-size: 2rem;
}
.navbar-brand .text-logo .underlined {
    text-decoration: underline;
    text-decoration-color: dodgerblue;
    text-decoration-thickness: from-font;
    text-underline-offset: 4px;
}

a.external:after {
    content: "⎋";
    margin-left: 0.25em;
    display: inline-block;
    transform: scale(-1, 1);
    opacity: .5;
    font-size: .75em;
}

.wati {
    font-family: caveat;
    font-size: 2.5rem;
    display: block;
    margin-right: 0.2em;
    text-decoration: none;
    transform: rotate(-3deg);
}

.btn-primary {
    background-color: dodgerblue;
    border-color: dodgerblue;
}
